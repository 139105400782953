import * as React from 'react';
import s from './ProductRibbon.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';

export enum DataHook {
  Root = 'product-widget-ribbon',
}

export type IProductRibbonProps = IProvidedGlobalProps;

@withGlobalProps
export class ProductRibbon extends React.Component<IProductRibbonProps> {
  public render(): React.ReactNode {
    const {
      globals: {product},
    } = this.props;
    return (
      <span data-hook={DataHook.Root} aria-hidden="true" className={s.productRibbon}>
        {product.ribbon}
      </span>
    );
  }
}
