import * as React from 'react';
import classnames from 'classnames';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {IProductPrice} from '../../types/app-types';
import {BasePrice} from '@wix/wixstores-client-common-components/dist/es/src/BasePrice/BasePrice';
import {unitsTranslations} from './unitsTranslations';

import s from './ProductPrice.scss';
import {PriceBreakdown} from '@wix/wixstores-client-common-components/dist/es/src/PriceBreakdown/PriceBreakdown';

export interface IProductPriceProps extends IProvidedTranslationProps, IProductPrice, IProvidedGlobalProps {}

export enum DataHook {
  Root = 'product-price',
  FormattedPrice = 'formatted-primary-price',
  FormattedPriceRange = 'price-range-from',
  ProductBasePrice = 'product-base-price',
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductPrice extends React.Component<IProductPriceProps> {
  private renderPrimary() {
    const {formattedComparePrice, formattedPrice, comparePrice, t} = this.props;
    const {hasDiscount} = this.props.globals;

    return (
      <>
        <span data-hook={DataHook.FormattedPrice}>{hasDiscount ? formattedComparePrice : formattedPrice}</span>
        <span className="sr-only" data-hook="sr-formatted-primary-price">
          {t(comparePrice ? 'sr.PRODUCT_PRICE_AFTER_DISCOUNT' : 'sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT')}
        </span>
      </>
    );
  }

  private renderSecondary() {
    const {formattedPrice, t} = this.props;

    return (
      <>
        <span data-hook="formatted-secondary-price" className={classnames(s.secondary, s.crossed)}>
          <span>{formattedPrice}</span>
        </span>
        <span className="sr-only" data-hook="sr-formatted-secondary-price">
          {t('sr.PRODUCT_PRICE_BEFORE_DISCOUNT')}
        </span>
      </>
    );
  }

  private renderPrice() {
    const {hasDiscount} = this.props.globals;

    return (
      <>
        {hasDiscount && this.renderSecondary()}
        {this.renderPrimary()}
      </>
    );
  }

  private renderPriceRange(formattedFromPrice: string) {
    const {t} = this.props;
    return (
      <>
        <span className="sr-only" data-hook="sr-formatted-price-range">
          {t('sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT')}
        </span>
        <span data-hook={DataHook.FormattedPriceRange}>
          {t('productWidget.price.from.label', {formattedAmount: formattedFromPrice})}
        </span>
      </>
    );
  }

  private renderBasePrice() {
    const {
      formattedPricePerUnit,
      pricePerUnitData: {baseQuantity, baseMeasurementUnit},
    } = this.props;

    const noun = baseQuantity === 1 ? 'singular' : 'plural';
    const unitTranslation = this.props.t(unitsTranslations[baseMeasurementUnit].abbr);
    const screenReaderText = this.props.t('productWidget.sr.units.basePrice.label', {
      basePrice: formattedPricePerUnit,
      units: `${baseQuantity} ${this.props.t(unitsTranslations[baseMeasurementUnit][noun])}`,
    });

    return (
      <BasePrice
        data-hook={DataHook.ProductBasePrice}
        className={s.basePrice}
        formattedPricePerUnit={formattedPricePerUnit}
        baseQuantity={baseQuantity}
        unitTranslation={unitTranslation}
        screenReaderText={screenReaderText}
      />
    );
  }

  private readonly sendClickShippingInfoLinkSfEvent = () => {
    const {clickShippingInfoLinkSf} = this.props.globals;
    clickShippingInfoLinkSf();
  };

  public render(): React.ReactNode {
    const {price, priceRange} = this.props;
    const {isDisabled} = this.props.globals;
    const shouldRenderBasePrice = this.props.globals.experiments.isBasePriceEnabled && this.props.formattedPricePerUnit;
    const {shouldRenderTaxDisclaimer, taxDisclaimer, shippingDisclaimer} = this.props.globals.priceBreakdown;

    if (!price && isDisabled) {
      return null;
    }

    return (
      <>
        <div data-hook={DataHook.Root} className={s.productPrice}>
          {priceRange && priceRange.fromPriceFormatted
            ? this.renderPriceRange(priceRange.fromPriceFormatted)
            : this.renderPrice()}
        </div>
        {shouldRenderBasePrice && this.renderBasePrice()}
        {(shouldRenderTaxDisclaimer || shippingDisclaimer?.show) && (
          <PriceBreakdown
            shouldRenderTaxDisclaimer={shouldRenderTaxDisclaimer}
            shippingDisclaimer={shippingDisclaimer}
            taxDisclaimerLabel={taxDisclaimer}
            whenShippingDisclaimerDialogOpen={this.sendClickShippingInfoLinkSfEvent}
            className={s.priceBreakdown}
          />
        )}
      </>
    );
  }
}
